<template>
    <div class="p-pegas">
        <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
            <div class="card-table-body ifon-add-title-block">
                <el-row :gutter="20">
                    <el-col :xs="24" :sm="20">
                        <div class="caz-blocks-sarcho-title">
                            <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                                {{$t('message.nv_monitoring')}}
                            </div>
                            <div class="block-sarche">
                                <div
                                    class="
                                        header__search
                                        my__header__search
                                        d-flex
                                    "
                                >
                                    <el-input
                                        :placeholder="$t('message.find')"
                                        prefix-icon="el-icon-search"
                                        :class="mode ? 'input__day' : 'input__night'"
                                        v-model="sarcheBlocks"
                                        size="small"
                                        round
                                    ></el-input>
                                    <el-date-picker
                                        v-model="value2"
                                        type="date"
                                        :placeholder="$t('message.from')"
                                        :class="mode ? 'input__day' : 'input__night'"
                                        size="small"
                                    >
                                    </el-date-picker>
                                    <el-date-picker
                                        v-model="value3"
                                        type="date"
                                        :class="mode ? 'input__day' : 'input__night'"
                                        :placeholder="$t('message.before')"
                                        size="small"
                                    >
                                    </el-date-picker>
                                </div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :xs="24" :sm="4" class="flex-style mbtop5 text-right">
                        <div class="data-hr">
                            <i class="el-icon-date"></i> 20.08.2021
                        </div>
                        <div class="time-hr">
                            Среда
                            <!-- <i class="el-icon-time"></i> 02:20 -->
                        </div>
                    </el-col>
                </el-row>

                <table class="table-my-code table-bordered mt-5">
                    <thead>
                        <tr class="total-th">
                            <th class="wq33">Опоздания (более 15 минут)</th>
                            <th class="wq33">Опоздания (до 15 минут)</th>
                            <th class="wq33">Во время</th>
                        </tr>
                        <!-- <tr>
                            <th>Время Ухода</th>
                            <th>Опаздания</th>
                            <th>Сверхурочная</th>
                        </tr> -->
                    </thead>
                    <tbody>
                        <tr>
                            <td class="td-danger">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-warning">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-success">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-danger">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-warning">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-success">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-danger">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-warning">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-success">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-danger">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-warning">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-success">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-danger">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-warning">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-success">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-danger">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-warning">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-success">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-danger">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-warning">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-success">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-danger">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-warning">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-success">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-danger">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-warning">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-success">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-danger">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-warning">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-success">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-danger">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-warning">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-success">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-danger">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-warning">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-success">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-danger">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-warning">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-success">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-danger">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-warning">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-success">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-danger">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-warning">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-success">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-danger">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-warning">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-success">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-danger">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-warning">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-success">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-danger">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-warning">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-success">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-danger">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-warning">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-success">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-danger">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-warning">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-success">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-danger">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-warning">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-success">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-danger">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-warning">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-success">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-danger">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-warning">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-success">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-danger">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-warning">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                            <td class="td-success">
                                <div>
                                    <div>Andrew Burns</div>
                                    <small class="text-muted">
                                        <i class="el-icon-s-cooperation"> </i>
                                        Dasturchi
                                    </small>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- end ifon-add-title-block -->
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
// @ is an alias to /src
export default {
    name: "monitoring",
    components: {},
    data() {
        return {
            sarcheBlocks: "",
            value3: "",
            value2: "",
        };
    },
    computed: {
    ...mapGetters({
      mode: "MODE"
    })
  },
};
</script>
<style>
.wq33 {
    width: 33%;
}
</style>
